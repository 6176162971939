import { capitalize, keyBy } from 'lodash'

import { Box, CompletionMetricTile } from '@cutover/react-ui'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DashboardComponent } from 'main/components/dashboards/widgets/types'
import { ConfigModel } from 'main/data-access'

/**
 * Displays the RTA Timings on PIR Dashboard
 */

export const RtaWidget = ({ data }: { data: DashboardComponent }) => {
  return data.rto_rta ? (
    <MrdDashboardWidget title={data.name || 'RTA Timings'} fullWidth>
      <WidgetContent {...data.rto_rta} />
    </MrdDashboardWidget>
  ) : null
}

const WidgetContent = ({ rto_status, rto, rta }: Required<DashboardComponent>['rto_rta']) => {
  const { rtoStatuses } = ConfigModel.useGet()
  const colorLookup = keyBy(rtoStatuses, 'id')

  return (
    <Box justify="between" direction="row" align="stretch" width="100%">
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile label={rto.name} timeString={rto.value} />
      </Box>
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile label={rta.name} diff={rta.diff} timeString={rta.value} />
      </Box>
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile
          label={rto_status.name}
          pill={{ label: capitalize(rto_status.value), size: 'medium', color: colorLookup[rto_status.value].color }}
        />
      </Box>
    </Box>
  )
}
